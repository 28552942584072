// import useJwt from '@/auth/jwt/useJwt'
// import * as Realm from 'realm-web'
import store from '@/store'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style

export const isUserLoggedIn = () => true
// const app = store.getters['app/realmApp']

// // const app = new Realm.App({ id: 'partnersrayoappcom-ucuyv' })
// // console.log(app)
// // console.log(88)
// // podría estar loggueado ?
// // calcula y updatea userdata

// const resultado = (app.currentUser !== null && app.currentUser !== undefined) ? app.currentUser.customData : null
// if (resultado !== null && resultado !== undefined) {
//   // console.log(app.currentUser.id)
//   // const texto = `realm-web:app(${app.id}):user(${app.currentUser.id}):accessToken`
//   // console.log(texto)
//   // console.log(localStorage.getItem(texto))
//   resultado.providerData = (app.currentUser !== null && app.currentUser !== undefined) ? app.currentUser.profile : null
// }
// const procesamiento = (app.currentUser !== null && app.currentUser !== undefined) ? resultado : null
// store.commit('app/SET_USER_DATA', procesamiento)
// // ff calcula y updatea userdata

// if (app.currentUser !== undefined && app.currentUser !== null) {
//   if (app.currentUser.isLoggedIn && app.currentUser.state === 'active') {
//     store.commit('app/SET_IS_USER_LOGGED_IN', true)
//     return app.currentUser.customData && app.currentUser.accessToken
//   }
//   store.commit('app/SET_IS_USER_LOGGED_IN', false)
//   return false
// }
// return false
// export const updateUserData = () => {
//   // JSON.parse(localStorage.getItem('userData'))
//   const resultado = (store.getters['app/realmApp'].currentUser !== null && store.getters['app/realmApp'].currentUser !== undefined) ? store.getters['app/realmApp'].currentUser.customData : null
//   if (resultado !== null && resultado !== undefined) {
//     resultado.providerData = (store.getters['app/realmApp'].currentUser !== null && store.getters['app/realmApp'].currentUser !== undefined) ? store.getters['app/realmApp'].currentUser.profile : null
//   }
//   const procesamiento = (store.getters['app/realmApp'].currentUser !== null && store.getters['app/realmApp'].currentUser !== undefined) ? resultado : null
//   store.commit('app/SET_USER_DATA', procesamiento)
//   return procesamiento
// }

// eslint-disable-next-line arrow-body-style
export const getUserData = () => {
  // console.log('ray')
  // console.log(store)
  // console.log(store.getters['app/userData'])
  return store.getters['app/userData']
}
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */

// acá definimos la ruta por defecto que sigue cada rol de la webapp
export const getHomeRouteForLoggedInUser = userRole => {
  // if (userRole === 'admin') return '/apps/postulaciones/list'
  // eslint-disable-next-line no-underscore-dangle
  if (userRole === 'admin') return `/apps/epas/view/${store.getters['app/realmApp'].currentUser.customData._id}`

  if (userRole === 'client') return { name: 'access-control' }
  if (userRole === 'rayo') return '/apps/postulaciones/list'
  // eslint-disable-next-line no-underscore-dangle
  // if (userRole === 'epa') return `/apps/epas/view/${store.getters['app/realmApp'].currentUser.customData._id}`
  if (userRole === 'epa') return '/pages/faq'
  return { name: 'auth-login' }
}
