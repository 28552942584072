import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes

// import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line no-unused-vars
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    { path: '/', redirect: { name: 'auth-login' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line consistent-return
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, _, next) =>
// console.log(22)
// eslint-disable-next-line no-unused-vars
// const isLoggedIn = isUserLoggedIn()

// if (!isLoggedIn) {
//   if (to.name === 'auth-login') {
//     return next()
//   }
//   return next({ name: 'auth-login' })
// }

// si no puede navegar por autorización debe enviarlo a login
// console.log(888)

// console.log(to.meta.action)
// const userData = getUserData()

// bloque ok

// if (!canNavigate(to)) {
//   if (!isLoggedIn) return next({ path: '/login' })
//   return next({ name: 'misc-not-authorized' })
// }
// if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   next(getHomeRouteForLoggcustomDataedInUser(userData !== null ? userData.role : null))
// } return next()

  // bloque ok
  // eslint-disable-next-line implicit-arrow-linebreak
  next())
// console.log(userData)
// console.log(getHomeRouteForLoggedInUser(userData ? userData.role : null))
// console.log(isLoggedIn)
// console.log(canNavigate(to))
// console.log(canNavigate())

//  if (!isLoggedIn) return next({ name: 'login' })
//     // eslint-disable-next-line prefer-template
//     console.log("can't: " + to.name)
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })
//     console.log('no está loggueado')
//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })

// if (canNavigate(to) && !isLoggedIn) { return next({ name: 'auth-login' }) }

// esto aplica para ciertas rutas en las cuales se debe redireccionar una vez que se logre exitoso el login
// Redirect if logged in

// if (!isLoggedIn) return next({ name: 'auth-login' })
// console.log(getUserData())

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
