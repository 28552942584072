import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueExpandableImage from 'vue-expandable-image'

import i18n from '@/libs/i18n'
// firebase juan andrés
// import firebase from 'firebase'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// const firebaseConfig = {
//   apiKey: 'AIzaSyDlXXRykQOlEvZmrJW_lIiFz7AqdsiiZjk',
//   authDomain: 'entregasrayo.firebaseapp.com',
//   databaseURL: 'https://entregasrayo.firebaseio.com',
//   projectId: 'entregasrayo',
//   storageBucket: 'entregasrayo.appspot.com',
//   messagingSenderId: '338070330126',
//   appId: '1:338070330126:web:cd4922b024d627e33d955f',
//   measurementId: 'G-RGB5HJ0B1Q',
// }
// firebase.initializeApp(firebaseConfig)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueExpandableImage)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
