import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    realmApp: {},
    isUserLoggedIn: false,
    userData: { },
    realmGoogleCredentials: {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    realmApp: state => state.realmApp,
    isUserLoggedIn: state => state.isUserLoggedIn,
    userData: state => state.userData,
    realmGoogleCredentials: state => state.realmGoogleCredentials,
  },
  mutations: {

    SET_REALM_APP(state, val) {
      state.realmApp = val
    },
    SET_IS_USER_LOGGED_IN(state, val) {
      state.isUserLoggedIn = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
    SET_REALM_GOOGLE_CREDENTIALS(state, val) {
      //  const redirectUri = 'https://partners.rayoapp.com/handleAuthRedirect'
      // DESARROLLO 8081 LOCALHOST
      // const redirectUri = 'http://localhost:8081/handleAuthRedirect'

      state.realmGoogleCredentials = val.google(this.state.appConfig.layout.redirectUri)
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
}
