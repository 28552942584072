import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  faqData: {
    // payment
    // payment: {
    //   icon: 'CreditCardIcon',
    //   title: 'Pagos',
    //   subtitle: 'Todo acerca de tus pagos',
    //   qandA: [
    //     {
    //       question: '¿Cual es el periodo de pagos para las entregas realizadas?',
    //       ans:
    //         'Las entregas realizadas en un mes serán pagadas el día 5 del mes cerrado siguiente. por ejemplo si realizaste 1000 entregas el hasta el 31 de enero estas serán pagadas el 5 de marzo.',
    //     },
    //     {
    //       question: 'Los montos que recibí de pago no me cuadran. Tengo objeciones respecto a ciertas entregas no pagadas.',
    //       ans:
    //         'Pronto liberaremos la plataforma en la que podrás ver en tiempo real tus pedidos realizados y los pagos asociados. Por lo pronto por favor ponte en contacto a xxxxx@shhshs.cl adjuntando la mayor cantidad de evidencias para que uno de nuestros ejecitiv@s pueda revisar el descuadre y darte solución a la brevedad.',
    //     },
    //     {
    //       question: '¿Cuales son los montos que se pagan porcada tipo de pedidos?',
    //       ans:
    //         'Pronto liberaremos la plataforma en la que podrás ver en tiempo real tus pedidos realizados y los pagos asociados. Por lo pronto por favor ponte en contacto a xxxxx@shhshs.cl adjuntando la mayor cantidad de evidencias para que uno de nuestros ejecitiv@s pueda revisar el descuadre y darte solución a la brevedad.',
    //     },
    //   ],
    // },
    // // delivery
    // delivery: {
    //   icon: 'UserIcon',
    //   title: 'Repartidores(as)',
    //   subtitle: 'Which license do I need?',
    //   qandA: [
    //     {
    //       question: 'Cómo puedo hacer para inscribir nuevos repartidores(as)',
    //       ans:
    //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
    //     },
    //     {
    //       question:
    //         '¿Cuanto tiempo toma elproceso de inscripción d enuevos repartidores?',
    //       ans:
    //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
    //     },
    //   ],
    // },

    support_rayos: {
      icon: 'UserIcon',
      title: 'Asistencia a Epas o Rayos en Ruta',
      subtitle: 'Epas Felices = Clientes Felices',
      qandA: [
        {
          question: 'No puedo loguearme a la aplicación',
          ans:
            '<a target="_blank" href="https://landbot.online/v3/H-1413776-XH7MYTPNKYAH3DIK/index.html"> Abrir Chatbot de soporte a Rayos en ruta</a><br>',
        },
        // {
        //   question:
        //     '¿Cuanto tiempo toma elproceso de inscripción d enuevos repartidores?',
        //   ans:
        //     'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        // },
      ],
    },
    support_task_edit_generic: {
      icon: 'UserIcon',
      title: 'Modificación de data en sistemas RAYO',
      subtitle: 'Cambia información de la entrega',
      qandA: [
        {
          question: 'Edición de próximo delivery',
          ans:
            'Este bot permite que edites información de la entrega próxima dado un ORDER_ID. <br> <strong>NOTA:En caso que la tarea ya esté asignada a un rayo no podrás editar la información referida a dirección.</strong><br>'
            + 'Los campos que podrán ser editados son:<br>'
            + '<ul><li>Nombre de persona que recibe</li><li>Dirección de persona que recibe <strong>(Seleccionable desde Google Maps)</strong></li><li>Información adicional a la dirección de persona que recibe</li><li>Email de persona que recibe</li><li>Teléfono de persona que recibe</li><li>Nombre de persona que recibe</li><ul> <br>'
            + '<a target="_blank" href="https://landbot.online/v3/H-1413413-5POTLJ9QBRJ0VK1T/index.html"><strong>ABRIR EL BOT DE MODIFICACIÓN DE INFORMACIÓN DE PRÓXIMO DELIVERY</strong></a>',
        },
        // {
        //   question:
        //     '¿Cuanto tiempo toma elproceso de inscripción d enuevos repartidores?',
        //   ans:
        //     'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
        // },
      ],
    },
    // cancellation and return
    // cancellationReturn: {
    //   icon: 'RefreshCwIcon',
    //   title: 'Cancellation & Return',
    //   subtitle: 'Which license do I need?',
    //   qandA: [
    //     {
    //       question: 'Can my security guard or neighbour receive my shipment if I am not available?',
    //       ans:
    //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
    //     },
    //     {
    //       question: 'How can I get the contact number of my delivery agent?',
    //       ans:
    //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
    //     },
    //     {
    //       question: 'How can I cancel my shipment?',
    //       ans:
    //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
    //     },
    //     {
    //       question: 'I have received a defective/damaged product. What do I do?',
    //       ans:
    //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
    //     },
    //     {
    //       question: 'How do I change my delivery address?',
    //       ans:
    //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    //     },
    //     {
    //       question: 'What documents do I need to carry for self-collection of my shipment?',
    //       ans:
    //         'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Tortor consequat id porta nibh. Id aliquet lectus proin nibh nisl condimentum id venenatis a. Faucibus nisl tincidunt eget nullam non nisi. Enim nunc faucibus a pellentesque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Nec nam aliquam sem et tortor consequat id. Fringilla est ullamcorper eget nulla facilisi. Morbi tristique senectus et netus et.',
    //     },
    //     {
    //       question: 'What are the timings for self-collecting shipments from the Delhivery Branch?',
    //       ans:
    //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.',
    //     },
    //   ],
    // },
    // my orders
    // myOrders: {
    //   icon: 'PackageIcon',
    //   title: 'Mis Pedidos',
    //   subtitle: 'Todo acerca de tu gestión',
    //   qandA: [
    //     {
    //       question: 'Donde puedo ver el listado de pedidos que he entregado',
    //       ans:
    //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
    //     },

    //   ],
    // },
    // product and services
    // productServices: {
    //   icon: 'SettingsIcon',
    //   title: 'Product & Services',
    //   subtitle: 'Which license do I need?',
    //   qandA: [
    //     {
    //       question: 'How can I register a complaint against the courier executive who came to deliver my order?',
    //       ans:
    //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
    //     },
    //     {
    //       question: 'The status for my shipment shows as ‘not picked up’. What do I do?',
    //       ans:
    //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
    //     },
    //     {
    //       question: 'How can I get a proof of delivery for my shipment?',
    //       ans:
    //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
    //     },
    //     {
    //       question: 'How can I avail your services?',
    //       ans:
    //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
    //     },
    //   ],
    // },
  },
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
